<template>
  <section class="container">
    <div v-if="!isComplete && !tokenApp" class="change-password"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <h3 class="title">パスワード変更</h3>

      <div  class="change-password__container step">

        <h4>新しいパスワードをご入力の上、「登録する」ボタンを押してください。</h4>

        <div class="step__register">

          <ValidationObserver
            slim
            tag="form"
            ref="observer"
            @submit.prevent="validateBeforeSubmit()">

            <div class="step__form ">

              <div class="step__form__group">

                <!--password-->
                <div class="form__control">
                  <label>
                    パスワード
                    <span class="required"
                          v-text="'必須'"/>
                  </label>

                  <div class="form__control__input">

                    <span class="required"
                          v-text="'必須'"/>

                    <InputField v-model="form.new_password"
                                type="password"
                                rules="required|password|minmax:8,32|password_contain"
                                class="w-100"
                                field="パスワード"
                                vid="new_password"/>

                  </div>
                </div>

                <!--confirm password-->
                <div class="form__control">
                  <label>
                    パスワード（確認）
                    <span class="required"
                          v-text="'必須'"/>
                  </label>

                  <div class="form__control__input">

                    <span class="required"
                          v-text="'必須'"/>

                    <InputField v-model="form.new_password_confirm"
                                type="password"
                                rules="required|password|minmax:8,32|password_contain|confirmed_password:new_password"
                                class="w-100"
                                field="パスワード（確認）"
                                vid="new_password_confirm"/>
                  </div>

                </div>

                <div class="form__control">
                  <label />
                  <div class="form__control__input">
                    <span class="required"/>
                    <ul class="note">
                      <li>半角英数8文字以上でご入力ください。</li>
                      <li>※大文字・小文字・数字を各1文字以上含む。</li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>

            <!--action only pc-->
            <button class="btn__register" type="submit">
              登録する
              <ChevronRightIcon size="24"/>
            </button>

          </ValidationObserver>

        </div>
      </div>

    </div>

    <div v-else class="change-password-complete"
         :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
      <h3 class="title">パスワード変更</h3>

      <div class="change-password-complete__container step">

        <div class="step__register">

          <div class="step__block">
            <h4>パスワードを変更しました。</h4>

            <a class="btn__register" @click.prevent="openPageNative('Profile.Mypage')">
              マイページへ
              <ChevronRightIcon size="24"/>
            </a>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
// Store
import store from '@/store'
// Component
import InputField from '@/components/form/InputField'
// Icon
import { ChevronRightIcon } from 'vue-feather-icons'
import { mapActions, mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
import { openPageNative } from '@/utils/helpers'
import Cookie from 'js-cookie'

export default {
  name: 'ChangePassword',

  mixins: [Common],

  components: {
    InputField,
    ChevronRightIcon
  },

  beforeRouteEnter (to, from, next) {
    // check param on mobile app
    if (to.query && to.query.email && to.query.token) {
      next()
    } else {
      store.dispatch('auth/checkUserToken', { token: to.params.token }).then(result => {
        if (result.status === 204) {
          next()
        } else {
          if (this.checkWebview()) {
            openPageNative('Expired')
          } else next({ name: 'Expired' })
        }
      })
    }
  },

  data () {
    return {
      form: {
        new_password: '',
        new_password_confirm: '',
        token: this.$route.params.token
      },
      isComplete: false,
      tokenApp: Cookie.get('bearer_token')
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  computed: {
    ...mapState('auth', ['fcmToken', 'profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  methods: {
    ...mapActions('auth', ['userResetPassword', 'userProfile']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.handleSubmit()
      }
    },

    handleSubmit () {
      // handle API FORGET PASSWORD
      // Check webview add payload another
      if (this.checkWebview()) {
        this.form = {
          ...this.form,
          is_mobile_app: 1,
          fcm_token: this.fcmToken
        }
      }
      this.userResetPassword(this.form).then(result => {
        if (result.status === 200) {
          // SEND NEW TOKEN TO APP
          this.triggerEventApp('CHANGE_TOKEN', {
            token: result.data.data.access_token
          })
          // SET COOKIE
          store.commit('auth/SET_TOKEN', result.data.data.access_token)
          Cookie.set('bearer_token', result.data.data.access_token, { expires: 7, secure: true })

          this.userProfile().then(_ => {
            this.isComplete = true
          })
        } else {
          this.handleSetErrors(result.data.errors)
        }
      })
    },

    handleSetErrors (errors) {
      errors.forEach(x => (
        this.$refs.observer.setErrors({
          [x.field]: x.message
        })
      ))
    }
  }
}
</script>

<style lang="scss" scoped>
.change-password, .change-password-complete {
  padding: 90px 0;
  @media #{$info-phone} {
    padding: 85px 0;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 85px;
    }
  }
  &.padding-webview {
    padding: 40px 0 0;
  }

  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }

  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  .step {
    padding: 65px;
    @media #{$info-phone} {
      padding: 48px 18px;
    }

    &__form {
      background-color: #E7E5DE;
      border-radius: 6px;
      margin-bottom: 60px;
      @media #{$info-phone} {
        padding: 24px 18px;
        margin-bottom: 20px;
      }

      &__group {
        padding: 24px;
        @media #{$info-phone} {
          padding: 0;
          margin-bottom: 38px;
        }
        .form__control {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          @media #{$info-phone} {
            display: block;
            margin-bottom: 34px;
          }
          &:nth-child(2) {
            margin-bottom: 24px;
          }
          label {
            width: 235px;
            flex-shrink: 0;
            @include font-size(18px);
            margin-top: 13px;
            @media #{$info-phone} {
              width: 100%;
              font-size: 16px;
              display: block;
              margin-bottom: 12px;
              margin-top: 0;
            }
            .required {
              display: none;
              color: #C66C44;
              @include font-size(14px);
              font-family: $font-family-title-B;
              margin-top: 16px;
              margin-left: 8px;
              @media #{$info-phone} {
                display: inline-block;
                margin-top: 0;
              }
            }
          }
        }

        .form__control__input {
          display: flex;
          align-items: flex-start;
          flex: 1;
          .required {
            color: #C66C44;
            @include font-size(14px);
            font-weight: bold;
            flex-shrink: 0;
            width: 52px;
            margin-top: 16px;
            @media #{$info-phone} {
              display: none;
            }
          }

          .note {
            li {
              @include font-size(16px);
              @media #{$info-phone} {
                font-size: 14px;
              }
            }
          }
        }

      }

    }

    .btn__register {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 360px;
      width: 100%;
      border-radius: 6px;
      color: #ffffff;
      background: #6F8784;
      font-family: $font-family-title;
      @include font-size(18px);
      padding: 14px;
      margin: auto;
      cursor: pointer;

      @media #{$info-phone} {
        font-size: 16px;
        padding: 10px;
        max-width: 100%;
      }

      svg {
        margin-left: 5px;
      }
    }

    h4 {
      text-align: center;
      font-weight: 500;
      @include font-size(18px);
      margin-bottom: 45px;
      @media #{$info-phone} {
        margin-bottom: 20px;
      }
    }
  }

}

.change-password-complete {
  .step {
    &__block {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      padding: 22px;
      @media #{$info-phone} {
        padding: 18px;
      }
    }

    h4 {
      text-align: center;
      font-weight: 500;
      color: $default-green;
      @include font-size(24px);
      margin-bottom: 35px;
      @media #{$info-phone} {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
@media #{$info-phone} {
  .container {
    padding: 0;
  }
}
</style>
